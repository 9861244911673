import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { red } from "@material-ui/core/colors"
import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import LokalizedLink from "../components/localizedLink"
import { capitalize } from "@material-ui/core/utils"
import styled from "styled-components"
import "./card.css"

const useStyles = makeStyles(theme => ({
  root: {
    /*maxWidth: 345,*/
    boxShadow: "none",
    backgroundColor: "#F0F3F7",
    borderBottom: "2px solid black",

  },
  imageWrapper: {
    overflow: "hidden"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    transition: "transform .5s ease",
    "&:hover": {
      transform: "scale(1.1)"
    }
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  link: {
    color: theme.palette.primary,
    transition: "transform .5s ease",
    '&:hover': {
      color: 'red',
    }
  }
}))

const Title = styled.span`
  /*width: calc(100%);*/
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
  position: relative;
  font-size: 26px;
  text-align: right;
  margin: 0 1rem auto auto;
  padding: 0;
  &::before,&::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: -0.2rem;
    width: 0px;
    height: 0.8rem;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.3s;
    /*background-color: red;*/
    opacity: 0;
    /*background-color: darken(orange,5%);*/
    background-image: linear-gradient(transparent calc(100% - 5px), orange 5px);
  }

  &:hover {
    cursor: pointer;
    &::before,&::after {
      width: 100%;
      background-size: 100% 100%;
      opacity: 1;
    }

  }
`

const SingleLinkCard = ({ post }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  let { slug, title, date, excerpt, category, tags, featuredImage } = post

  return (
    <Card className={classes.root}>
      {/*<CardHeader title={category} subheader={title} />*/}
      <div className={classes.imageWrapper}>
        <CardMedia
          className={classes.media}
          image={featuredImage.childImageSharp.fluid.src}
          title={title}
        />
      </div>
      <CardContent>
        <LokalizedLink
          underline="none"
          style={{ textDecoration: "none"}}
          to={`/${slug}`}
        >

            <Typography variant="subtitle1" color="textPrimary" component="p" style={{ textAlign: "right"}}>
              <Title> {title} </Title>
            </Typography>
        </LokalizedLink>

        <Typography variant="subtitle2" color="textSecondary" component="p" style={{ textAlign: "right"}}>
          {excerpt}
          <LokalizedLink to={`/${slug}`}>
            <span className={classes.link}>{"المزيد"}</span>
          </LokalizedLink>
        </Typography>
      </CardContent>
    </Card>
  )
}

export default SingleLinkCard
