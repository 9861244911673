import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import LokalizedLink from "../components/localizedLink"
import { LocaleContext } from "../components/layout"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  }
}))

const AboutAuthor = ({ author }) => {
  const classes = useStyles()

  let { locale } = LocaleContext
  //const getRightLanguageBio(authorBio)

  let link = (
    <LokalizedLink to={`/author/${author.id}`}>{author.id}</LokalizedLink>
  )

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={link}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              ></Typography>
              {author.bio}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  )
}

export default AboutAuthor
