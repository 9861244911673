import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import classNames from 'classnames';
// import styled from '@emotion/styled';
import styled from 'styled-components';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import { graphql } from 'gatsby';

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

import ArrowRighttIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { kebabCase } from 'lodash';
import ShareIcon from '@material-ui/icons/Share';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import LocalizedLink from '../components/localizedLink';
import Layout, { LocaleContext } from '../components/layout';
import AboutAuthor from './AboutAuthor';
import SimilarArticlesAppBar from './SimilarArticlesAppBar';
import SimilarArticles from './SimilarArticles';
import SEO from '../components/seo';
import useTranslations from '../components/useTranslations';

import PostFooter from './post-footer';

const useStylesz = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    // bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 3,
  },
}));

function ScrollTop(props) {
  const { children, window, id } = props;
  const classes = useStylesz();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 600,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${id}`,
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  /*padding: 160px 1.0875rem 1.45rem;*/
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: theme.spacing(0),
    // paddingRight: theme.spacing(4),
    // paddingBottom: theme.spacing(2)
  },
  shareArea: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  main: {
    backgroundColor: theme.palette.primaryColor,
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    backgroundColor: theme.palette.primaryColor,
    margin: '-200px 0px 0px',
    borderRadius: '6px',

    '@media (min-width: 576px)': {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    '@media (min-width: 768px)': {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    '@media (min-width: 992px)': {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    '@media (min-width: 1200px)': {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },

    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },

  goBack: { transition: 'transform .3s ease-out' },
  goBackWrapper: {
    /* backgroundColor: 'red', */
    '&:hover $goBack': {
      color: 'green',
      right: '24px',
      transform: 'translate(10px, 0)',
    },
  },

}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 100, // default
    disableHysteresis: true,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      timeout={{ enter: 500, exit: 1000 }}
    >
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Place = ({ data, pageContext, ...props }) => {
  const theme = useTheme();
  const [completed, setCompleted] = React.useState(0);
  const [position, setPosition] = React.useState(0);

  /* React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldCompleted + diff, 100)
      })
    }

    const timer = setInterval(progress, 500)
    return () => {
      clearInterval(timer)
    }
  }, []) */

  const handleScroll = () => {
    const position = window.pageYOffset;

    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';

    const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
    setCompleted(percent);
    setPosition(h.clientHeight - position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { previous } = data;
  const { next } = data;
  const { locale } = data.resourcePost;
  const { isDefault } = data.resourcePost;
  const { tags } = data.resourcePost;
  const linkToShare = data.resourcePost.slug;
  const { category } = data.resourcePost;
  const author = data.allAuthorYaml.edges.find(
    (author) => author.node.id === data.resourcePost.authorId,
  ).node;

  /* const {
    algeria,
    libya,
    morocco,
    tunisia,
    mauritania,
    international,
  } = useTranslations(); */

  const { location } = data;

  const classes = useStyles();
  return (
    <div>
      {/* position > 140 && (
        <AppBar style={{ marginTop: 70 }}>
          <LinearProgress variant="determinate" value={completed} />
        </AppBar>
      ) */}
      <Wrapper>
        <Grid container>
          <Grid item xs={12}>
            <LocalizedLink to={`/category/${category}`}>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginLeft: 50 }}
                className={classes.goBackWrapper}
              >
                <Grid item>
                  <ArrowForwardIcon fontSize="large" className={classes.goBack} />
                </Grid>
                <Grid item>{useTranslations()[category]}</Grid>
              </Grid>
            </LocalizedLink>
          </Grid>
          {/* <Grid item xs={12} style={{ marginLeft: 60 }}>
        <Box fontFamily="montserrat" fontSize={64} fontStyle="italic">
          {title}
        </Box>
      </Grid> */}
        </Grid>

        <AppBar style={{ marginTop: 70 }}>
          <LinearProgress variant="determinate" value={completed} color="primary" />
        </AppBar>

        <Hidden xsDown>
          <Paper className={classes.paper}>
            <CssBaseline />
            <>
              <HideOnScroll>
                <AppBar
                  style={{
                    marginTop: 50,
                    zIndex: 4,
                    height: 'auto',
                    minWidth: 50,
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  {/* <Toolbar>
                    <SimilarArticlesAppBar
                      category="indonesian"
                      tags={["indonesian", "slangs"]}
                      currentArticleSlug={data.resourcePost.slug}
                      relatedPosts={data.relatedPosts}
                    />
                  </Toolbar> */}
                </AppBar>
              </HideOnScroll>
            </>
          </Paper>
        </Hidden>

        <Img fluid={data.resourcePost.featuredImage.childImageSharp.fluid} />

        <div id="textcontent">
          <Typography
            variant="h1"
            style={{
              /* fontStyle: "italic", */
              fontWeight: 'bold',
              textAlign: 'right',
              fontSize: '2.5rem',
              fontFamily: 'Almarai',
            }}
          >
            {data.resourcePost.title}
          </Typography>
          <Typography
            variant="body1"
            component="div"
            style={{
              fontSize: '1.2rem',
              lineHeight: '2rem',
              textAlign: 'right',
              fontFamily: 'Almarai',
            }}
          >
            <MDXRenderer>{data.resourcePost.body}</MDXRenderer>
          </Typography>
          <Divider
            variant="middle"
            component="div"
            className={classes.dividerColor}
          />
          <AboutAuthor author={author} />
          <Grid item xs={12}>
            <Divider
              variant="middle"
              component="div"
              className={classes.dividerColor}
            />
          </Grid>

          <Grid
            container
          >
            <Grid item>

              <ShareTwoToneIcon style={{ fontSize: 60, margin: 5 }} />
              <FacebookShareButton
                url={props.location.href}
                quote={data.resourcePost.excerpt}
                hashtag={tags.map((tag) => `#${useTranslations()[tag]}`).join(' ')}
              >
                <FacebookIcon iconFillColor="white" round />
              </FacebookShareButton>
            </Grid>

            <Grid item>
              <TwitterShareButton
                url={props.location.href}
                quote={data.resourcePost.excerpt}
                hashtag={tags.map((tag) => `#${useTranslations()[tag]}`).join(' ')}
              >
                <TwitterIcon iconFillColor="white" round />
              </TwitterShareButton>
            </Grid>

            <Grid item>
              <WhatsappShareButton
                url={props.location.href}
                quote={data.resourcePost.excerpt}
                hashtag={tags.map((tag) => `#${useTranslations()[tag]}`).join(' ')}

              >
                <WhatsappIcon iconFillColor="white" round />
              </WhatsappShareButton>
            </Grid>

            <Grid item>
              <FacebookMessengerShareButton
                url={props.location.href}
                quote={data.resourcePost.excerpt}
                hashtag={tags.map((tag) => `#${useTranslations()[tag]}`).join(' ')}

              >
                <FacebookMessengerIcon
                  iconFillColor="white"
                  round

                />
              </FacebookMessengerShareButton>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
            <Divider
              variant="middle"
              component="div"
              className={classes.dividerColor}
            />
          </Grid>

          <PostFooter {...{
            previous, next, locale, isDefault,
          }}
          />
        </div>

        {tags && tags.length ? (
          <div style={{ marginTop: '4rem' }}>
            <h4>الكلمات المفاحية</h4>
            <ul className="taglist">
              {tags.map((tag) => (
                <LocalizedLink
                  to={`/tags/${kebabCase(tag)}/`}
                  key={`${tag}tag`}
                >
                  <Chip label={useTranslations()[tag]} component="div" clickable color="secondary" />

                </LocalizedLink>
              ))}
            </ul>
          </div>
        ) : null}

        <SimilarArticles
          category="indonesian"
          tags={['indonesian', 'slangs']}
          currentArticleSlug={data.resourcePost.slug}
          relatedPosts={data.relatedPosts}
        />
      </Wrapper>
    </div>
  );
};

/*  {<HorizonalAd/> } */
/* category="indonesian"
tags={["indonesian", "slangs"]}
currentArticleSlug={data.blogPost.slug} */

export default Place;
export { ScrollTop };

export const query = graphql`
  query PlacePageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $locale: String
    $category: String!
  ) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    resourcePost(id: { eq: $id }) {
      id
      excerpt
      description
      body
      slug
      title
      authorId
      locale
      isDefault
      category
      tags
      keywords

      date(formatString: "MMMM DD, YYYY")
      ... on MdxResourcePost {
        id
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allAuthorYaml {
      edges {
        node {
          id
          bio
          twitter
        }
      }
    }
    previous: resourcePost(id: { eq: $previousId }) {
      id
      excerpt
      description
      locale
      slug
      title
      category
      tags
      date(formatString: "MMMM DD, YYYY")
    }
    next: resourcePost(id: { eq: $nextId }) {
      id
      excerpt
      description
      locale
      slug
      title
      category
      tags
      date(formatString: "MMMM DD, YYYY")
    }

    relatedPosts: allResourcePost(
      filter: { locale: { eq: $locale }, category: { eq: $category } }
    ) {
      edges {
        node {
          slug
          title
          date
          excerpt
          id
          isDefault
          locale
          category
          tags

          ... on MdxResourcePost {
            id
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
