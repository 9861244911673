import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { includes, orderBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ImgMediaCard from './ArticleCard';
import { getPostsFromQuery } from './blog';
import SingleLinkCard from './SingleLinkCard';
import { PortraitCard } from '../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const SimilarArticlesComponent = ({ articles }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {[...articles].map((article, i) => (
          <Grid item xs={12} sm={4} key={i}>
            {/* <ImgMediaCard
                slug={article.article.slug}
                title={article.article.title}
                date={article.article.date}
                excerpt={article.article.excerpt}
                category={article.article.category}
                tags={article.article.tags}
                featuredImage={article.article.featuredImage}
              /> */}

            <PortraitCard
              {...article.article}
              basePath={`/${article.article.slug}`}
            />

          </Grid>
        ))}
      </Grid>
    </div>
  );
};

class SimilarArticlesFactory {
  constructor(articles, currentArticleSlug) {
    this.articles = articles.filter(
      (aArticle) => aArticle.slug !== currentArticleSlug,
    );
    this.currentArticleSlug = currentArticleSlug;
    this.maxArticles = 3;
    this.category = null;
    this.tags = [];
  }

  setMaxArticles(m) {
    this.maxArticles = m;
    return this;
  }

  setCategory(aCategory) {
    this.category = aCategory;
    return this;
  }

  setTags(tagsArray) {
    this.tags = tagsArray;
    return this;
  }

  getArticles() {
    const {
      category, tags, articles, maxArticles,
    } = this;
    const identityMap = {};

    function getSlug(article) {
      return article.slug;
    }

    function addToMap(article) {
      const slug = getSlug(article);

      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          article,
          points: 0,
        };
      }
    }

    function addCategoryPoints(article, category) {
      const categoryPoints = 2;
      const slug = getSlug(article);

      if (article.category === category) {
        identityMap[slug].points += categoryPoints;
      }
    }

    function addTagsPoints(article, tags) {
      const tagPoint = 1;
      const slug = getSlug(article);

      article.tags.forEach((aTag) => {
        if (includes(tags, aTag)) {
          identityMap[slug].points += tagPoint;
        }
      });
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }

    for (const article of articles) {
      addToMap(article);
      addCategoryPoints(article, category);
      addTagsPoints(article, tags);
    }

    const arrayIdentityMap = getIdentityMapAsArray();
    const similarArticles = orderBy(arrayIdentityMap, ['points'], ['desc']);
    return similarArticles.splice(0, maxArticles);
  }
}

const SimilarArticles = (props) => {
  const {
    category, tags, currentArticleSlug, relatedPosts,
  } = props;
  const articles = getPostsFromQuery(relatedPosts);
  const similarArticles = new SimilarArticlesFactory(
    articles,
    currentArticleSlug,
  )
    .setMaxArticles(4)
    .setCategory(category)
    .setTags(tags)
    .getArticles();

  return <SimilarArticlesComponent articles={similarArticles} />;
};

export default SimilarArticles;
