import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { getPostsFromQuery } from "./blog"
import { includes, orderBy } from "lodash"
import ImgMediaCard from "./ArticleCard"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import ButtonBase from "@material-ui/core/ButtonBase"
import LocalizedLink from "../components/localizedLink"
import Divider from "@material-ui/core/Divider"
import CssBaseline from "@material-ui/core/CssBaseline"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    //padding: theme.spacing(1)
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    fontSize: `0.8em`
  },

  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    padding: theme.spacing(0),
    margin: "auto",
    maxHeight: 130
  },
  image: {
    minWidth: 70,
    width: 100,
    height: 80
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  divi: {
    "& hr": {
      height: `100 % !important`
    }
  },
  padding0: {
    padding: 0
  }
}))

const SimilarArticlesToolBarComponent = ({ articles }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        {articles.map((article, i) => {
          return (
            <Grid item xs={6} sm={3} key={`similarArticles${i}`}>
              <Grid container direction="row">
                <Grid item sm={11} md={11}>
                  <LocalizedLink
                    to={`/${article.article.slug}`}
                    className={classes.simpleLink}
                    style={{
                      textDecoration: "none"
                    }}
                  >
                    <ButtonBase className={classes.image}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src={
                          article.article.featuredImage.childImageSharp.fluid
                            .src
                        }
                      />
                    </ButtonBase>
                    {article.article.title}
                  </LocalizedLink>
                </Grid>
                <Grid
                  item
                  sm={1}
                  md={1}
                  className={classes.divi}
                  className={classes.padding0}
                >
                  {i < 3 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ height: 70, marginTop: 5, marginBottom: 5 }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

class SimilarArticlesFactory {
  constructor(articles, currentArticleSlug) {
    this.articles = articles.filter(
      aArticle => aArticle.slug !== currentArticleSlug
    )
    this.currentArticleSlug = currentArticleSlug
    this.maxArticles = 3
    this.category = null
    this.tags = []
  }

  setMaxArticles(m) {
    this.maxArticles = m
    return this
  }

  setCategory(aCategory) {
    this.category = aCategory
    return this
  }

  setTags(tagsArray) {
    this.tags = tagsArray
    return this
  }

  getArticles() {
    const { category, tags, articles, maxArticles } = this
    const identityMap = {}

    function getSlug(article) {
      return article.slug
    }

    function addToMap(article) {
      const slug = getSlug(article)

      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          article: article,
          points: 0
        }
      }
    }

    function addCategoryPoints(article, category) {
      const categoryPoints = 2
      const slug = getSlug(article)

      if (article.category === category) {
        identityMap[slug].points += categoryPoints
      }
    }

    function addTagsPoints(article, tags) {
      const tagPoint = 1
      const slug = getSlug(article)

      article.tags.forEach(aTag => {
        if (includes(tags, aTag)) {
          identityMap[slug].points += tagPoint
        }
      })
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map(slug => identityMap[slug])
    }

    for (let article of articles) {
      addToMap(article)
      addCategoryPoints(article, category)
      addTagsPoints(article, tags)
    }

    const arrayIdentityMap = getIdentityMapAsArray()
    const similarArticles = orderBy(arrayIdentityMap, ["points"], ["desc"])
    return similarArticles.splice(0, maxArticles)
  }
}

const SimilarArticlesAppBar = props => {
  const { category, tags, currentArticleSlug, relatedPosts } = props

  const articles = getPostsFromQuery(relatedPosts)
  const similarArticles = new SimilarArticlesFactory(
    articles,
    currentArticleSlug
  )
    .setMaxArticles(4)
    .setCategory(category)
    .setTags(tags)
    .getArticles()

  return <SimilarArticlesToolBarComponent articles={similarArticles} />
}

export default SimilarArticlesAppBar
