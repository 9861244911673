import {Link} from "gatsby"
import LocalizedLink from "../components/localizedLink"
import React from "react"
import {makeStyles, useTheme} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import IconButton from "@material-ui/core/IconButton"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

import {Box, Button, Chip, Typography} from "@material-ui/core"
import useTranslations from "../components/useTranslations"
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  moveOnHover: {
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.action.selected
    }
  },
  /* Styles applied to the inner `component` element if `moveOnHover={true}`. */
  moveOnHover: {
    transition: theme.transitions.create('background-color', {duration: theme.transitions.duration.shortest}),
    /*'&:hover': {
        textDecoration: 'none',
        backgroundColor: theme.palette.action.hover,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
    },*/

    "&:hover $ButtonLeft $ArrowLeftC": {
      color: '#3569fa',
      left: "24px",
      transform: 'translate( 10px, 0)'

    },
    "&:hover $ButtonRight $ArrowRightC": {
      color: '#3569fa',
      right: "24px",
      transform: 'translate(-10px, 0)'
    }
  },
  /* Styles applied to the root element if `selected={true}`. */
  ButtonLeft: {},
  ButtonRight: {},
  ArrowLeftC: {
    transition: 'transform .3s ease-out'
  },
  ArrowRightC: {
    transition: 'transform .3s ease-out'
  }
}))

const Footer = ({previous, next, locale, isDefault}) => {
  const classes = useStyles()
  const theme = useTheme()
  const {prev, nex} = useTranslations()
  return (<div className={classes.root}>
    <Grid container="container" direction="row" justify="space-between" alignItems="stretch">
      <Grid item="item" xs={4} style={{
          textAlign: "right"
        }} className={classes.moveOnHover}>
        {
          previous && (<Button component={LocalizedLink} to={isDefault
              ? `/${previous.slug}`
              : `${locale}/${previous.slug}/test`} variant="outlined" color="secondary" size="small" className={classes.ButtonLeft}>
            {theme.direction === "ltr" && <ArrowForwardIcon size={8} className={classes.ArrowLeftC}/>}
            {theme.direction === "rtl" && <ArrowBackIcon size={8} className={classes.ArrowLeftC}/>}

            <Box marginRight={0.5}>
              <Typography style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  fontFamily: 'Almarai'
                }}>{`${prev}: ${previous.title}`}</Typography>
            </Box>
          </Button>)
        }
      </Grid>
      <Grid item="item" xs={4} style={{
          textAlign: "left"
        }} className={classes.moveOnHover}>
        {
          next && (<Button component={LocalizedLink} to={isDefault
              ? `/${next.slug}`
              : `${locale}/${next.slug}/test`} variant="outlined" color="secondary" size="small" className={classes.ButtonRight}>
            <Box marginRight={0.5}>
              <Typography style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  fontFamily: 'Almarai'
                }}>{`${nex}: ${next.title}`}</Typography>
            </Box>
            {theme.direction === "ltr" && <ArrowBackIcon size={8} className={classes.ArrowRightC}/>}
            {theme.direction === "rtl" && <ArrowForwardIcon size={8} className={classes.ArrowRightC}/>}
          </Button>)
        }
      </Grid>
    </Grid>
  </div>)
}

export default Footer
